* {
  font-family: 'Arial', sans-serif;
  font-size: 20px;
}

body {
  background: rgb(176, 247, 255);
  background: linear-gradient(0deg, rgba(176, 247, 255, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 100%);
}

h1 {
  font-weight: bolder;
  text-align: center;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

p {
  text-align: center;
}

a,
div.nav-item {
  text-align: center;
}

[data-rmiz-modal-overlay="visible"] {
  opacity: 0.8;
  background-color: black !important;
  transition: none;
}

[data-rmiz-btn-unzoom=""] {
  display: none !important;
}

.logo {
  max-width: 20rem;
}

@media (max-width: 768px) {
  .logo {
    max-width: 12rem;
  }
}